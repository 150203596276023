import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from "../views/Home.vue"
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: "/home",
    component: () => import('@/views/Home.vue'),
    children: [
      {
        path: '/home',
        name: '首页',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 1,
          auth: true,
        },
        component: () => import('@/views/body.vue'),
      },

      {
        path: 'enquiry',
        name: 'enquiry',
        redirect: "/enquiryApply1",
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 10,
          auth: true,
        },
        component: () => import('../views/shangPiao/enquiry'),
        children: [
          {
            path: '/enquiryApply1',
            name: 'enquiryApply1',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryApply1'),
          },
          {
            path: '/enquiryApply2',
            name: 'enquiryApply2',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryApply2'),
          },
          {
            path: '/enquiryApply3',
            name: 'enquiryApply3',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 6,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryApply3'),
          },
          {
            path: '/enquiryPledgeApply',
            name: 'enquiryPledgeApply',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 6,
              auth: true,
            },
            component: () => import('@/views/shangPiao/enquiryPledgeApply'),
          },

        ],
      },
      // 出口保理融资
      {
        path: 'ckblrz',
        name: 'ckblrz',
        redirect: "/ckblrz1",
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 10,
          auth: true,
        },
        component: () => import('../views/shangPiao/factorageFinancing/ckblrz'),
        children: [
          {
            path: '/ckblrz1',
            name: 'ckblrz1',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/shangPiao/factorageFinancing/ckblrz1'),
          },
          {
            path: '/ckblrz2',
            name: 'ckblrz2',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/shangPiao/factorageFinancing/ckblrz2'),
          },
          {
            path: '/ckblrz3',
            name: 'ckblrz3',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/shangPiao/factorageFinancing/ckblrz3'),
          },
          {
            path: '/ckblrz4',
            name: 'ckblrz4',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/shangPiao/factorageFinancing/ckblrz4'),
          },
        ]
      },
      // 跨境驿
      {
        path: 'crossBorder',
        name: 'crossBorder',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 8,
          auth: true,
        },
        component: () => import('../views/kuaJing/crossBorder.vue'),
      },
      // 跨境关税保函产品申请
      {
        path: 'gsbhApplication',
        name: 'gsbhApplication',
        redirect: "/gsbhApply1",
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 8,
          auth: true,
        },
        component: () => import('@/views/kuaJing/guanshuibaohan/gsbhApplication'),
        children: [
          {
            path: '/gsbhApply1',
            name: 'gsbhApply1',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaohan/gsbhApply1'),
          },
          // 关税保函确认授信申请
          {
            path: '/creditExtension',
            name: 'creditExtension',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/project/projectKuaJing/creditExtension.vue'),
          },
          {
            path: '/gsbhApply2',
            name: 'gsbhApply2',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaohan/gsbhApply2'),
          },

        ],
      },
      // 跨境关税E保产品申请
      {
        path: 'borderApplication',
        name: 'borderApplication',
        redirect: "/crossBorderApply1",
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 8,
          auth: true,
        },
        component: () => import('../views/kuaJing/BorderApplication.vue'),
        children: [
          {
            path: '/crossBorderApply1',
            name: 'crossBorderApply1',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/crossBorderApply1'),
          },
          {
            path: '/crossBorderApply2',
            name: 'crossBorderApply2',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/crossBorderApply2'),
          },

        ],
      },
      // 跨境关税保证保险产品申请
      {
        path: 'gsbzbxApplication',
        name: 'gsbzbxApplication',
        redirect: "/gsbzbxApply1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('@/views/kuaJing/guanshuibaozheng/gsbzbxApplication'),
        children: [
          {
            path: '/gsbzbxApply1',
            name: 'gsbzbxApply1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaozheng/gsbzbxApply1'),
          },
          {
            path: '/gsbzbxApply2',
            name: 'gsbzbxApply2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuibaozheng/gsbzbxApply2'),
          },

        ],
      },
      // 跨境税信保产品申请
      {
        path: 'sxbApplication',
        name: 'sxbApplication',
        redirect: "/sxbApply1",
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 8,
          auth: true,
        },
        component: () => import('../views/kuaJing/shuixinbao/sxbApplication'),
        children: [
          {
            path: '/sxbApply1',
            name: 'sxbApply1',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbApply1.vue'),
          },
          // 税信宝确认授信申请第一步
          {
            path: '/sxbCreditExtension',
            name: 'sxbCreditExtension',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbCreditExtension.vue'),
          },
          // 税信宝确认授信申请第二步
          {
            path: '/sxbCreditExtension2',
            name: 'sxbCreditExtension2',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbCreditExtension2.vue'),
          },
          // 税信宝确认授信申请第二步
          {
            path: '/sxbCreditExtension3',
            name: 'sxbCreditExtension3',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 9,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbCreditExtension3.vue'),
          },
          {
            path: '/sxbApply2',
            name: 'sxbApply12',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/shuixinbao/sxbApply2.vue'),
          },
          {
            path: '/FinancingType',
            name: '融资类产品',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/prefecture/FinancingType.vue'),
          },
          {
            path: '/NotFinancingType',
            name: '非融资类产品',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/prefecture/NotFinancingType.vue'),
          },
          {
            path: '/CyShuJuDai',
            name: '产业数字贷',
            meta: {
              title: "湖北国际贸易数字化平台测试版",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/prefecture/CyShuJuDai.vue'),
          },

        ],
      },
      // 跨境关税履约保证保险产品申请
      {
        path: 'gslybzbxApplication',
        name: 'gslybzbxApplication',
        redirect: "/gslybzbxApply1",
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 8,
          auth: true,
        },
        component: () => import('@/views/kuaJing/guanshuilvyuebaozheng/gslybzbxApplication'),
        children: [
          {
            path: '/gslybzbxApply1',
            name: 'gslybzbxApply1',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 11,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuilvyuebaozheng/gslybzbxApply1'),
          },
          {
            path: '/gslybzbxApply2',
            name: 'gslybzbxApply2',
            meta: {
              title: "数字金融服务平台-商驿科技",
              index: 5,
              auth: true,
            },
            component: () => import('@/views/kuaJing/guanshuilvyuebaozheng/gslybzbxApply2'),
          },

        ],
      },
      {
        path: 'projectBusinessTicket',
        name: 'projectBusinessTicket',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectShangPiao/projectBusinessTicket.vue'),
      },
      {
        path: 'projectBusinessDetails',
        name: 'projectBusinessDetails',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 10,
          auth: true,
        },
        component: () => import('../views/project/projectShangPiao/projectBusinessDetails'),
      },
      {
        path: 'projectDiscountDetails',
        name: 'projectDiscountDetails',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectDiscountDetails'),
      },
      {
        path: 'projectApproveEasypasteDetails',
        name: '商票易贴审批通过',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectApproveEasypasteDetails'),
      },
      {
        path: 'projectPledgeApproveDetalis',
        name: '商票质押审批中',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectPledgeApproveDetalis'),
      },
      {
        path: 'projectApprovePledgeDetails',
        name: '商票质押审批通过',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 10,
          auth: true,
        },
        component: () => import('@/views/project/projectShangPiao/projectApprovePledgeDetails'),
      },

      // 跨境驿产品详情
      {
        path: '/projectCrossBorder',
        name: 'projectCrossBorder',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectCrossBorder.vue'),
      },
      // 关税E保产品预审详情
      {
        path: '/projectBorderDetails',
        name: 'projectBorderDetails',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectBorderDetails.vue'),
      },
      // 融资类详情
      {
        path: '/FinancingTypeDetails',
        name: 'FinancingTypeDetails',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/prefecture/FinancingTypeDetails.vue'),
      },
      // 非融资类详情
      {
        path: '/NotFinancingTypeDetails',
        name: 'NotFinancingTypeDetails',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/prefecture/NotFinancingTypeDetails.vue'),
      },
      // 产业数字贷产品详情
      {
        path: '/CyShuJuDaiDetails',
        name: '产业数字贷产品详情',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 5,
          auth: true,
        },
        component: () => import('@/views/project/projectKuaJing/prefecture/CyShuJuDaiDetails.vue'),
      },
      // 关税保函产品预审详情
      {
        path: '/projectBorderDetailsGsbh',
        name: 'projectBorderDetailsGsbh',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectBorderDetailsGsbh.vue'),
      },
      // 税信保产品预审详情
      {
        path: '/projectBorderDetailsSxb',
        name: 'projectBorderDetailsSxb',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectBorderDetailsSxb.vue'),
      },
      // 正式项目
      // 关税保函产品正式详情
      {
        path: '/regularProjectGsbh',
        name: 'regularProjectGsbh',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/regularProjectGsbh'),
      },
      // 关税E保产品正式详情
      {
        path: '/regularProjectGseb',
        name: 'regularProjectGseb',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/regularProjectGseb'),
      },
      // 税信保产品正式详情
      {
        path: '/regularProjectSxb',
        name: 'regularProjectSxb',
        meta: {
          title: "湖北国际贸易数字化平台测试版",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/regularProjectSxb'),
      },
      // 关税保证保险详情
      {
        path: '/projectGuaranteeDetails',
        name: 'projectGuaranteeDetails',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectGuaranteeDetails'),
      },
      // 出口保理融资
      {
        path: '/projectBorderDetailsCkblrz',
        name: 'projectBorderDetailsCkblrz',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectBorderDetailsCkblrz'),
      },
      // 关税履约保证保险
      {
        path: '/projectGuaranteeGslybzbx',
        name: 'projectGuaranteeGslybzbx',
        meta: {
          title: "数字金融服务平台-商驿科技",
          index: 9,
          auth: true,
        },
        component: () => import('../views/project/projectKuaJing/projectGuaranteeGslybzbx'),
      },
    ],
  },
  {
    path: '/initial',
    name: '等等',
    meta: {
      title: "湖北国际贸易数字化平台测试版",
      index: 14,
      auth: true,
    },
    component: () => import('@/views/result/initial'),
  },
  {
    path: '/gain',
    name: '等一等',
    meta: {
      title: "湖北国际贸易数字化平台测试版",
      index: 14,
      auth: true,
    },
    component: () => import('@/views/result/gain'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if (to.path === "/login") return next()
  const token = localStorage.getItem("token")
  if (to.path === "/initial") return next()
  if (to.path === "/gain") return next()


  if (!token) {
    return next("/login")
  }
  next()
})
export default router
